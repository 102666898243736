import { useMemo } from "react";
import GenericText from "../texts/GenericText";
import ISelect from "./ISelect";
import { customStyles, SelectCustom } from "./styles";

const Select = (props: ISelect) => {
  const { label, data, placeholder, removeDefaultValue = false } = props;

  const newData = useMemo(() => {
    if (removeDefaultValue) return data;
    if (label) {
      return [{ id: undefined, name: label }, ...data];
    } else if (placeholder) {
      return [{ id: undefined, name: placeholder }, ...data];
    }
    return data;
  }, [data, label, placeholder, removeDefaultValue]);

  return (
    <div style={{ width: props.width || "100%" }}>
      {label && (
        <GenericText margin="0px 0px 4px 0px" size="h6" color="black">
          {`${props.label}${props.required ? "*" : ""}`}
        </GenericText>
      )}
      <SelectCustom
        noOptionsMessage={() => "Sem Dados"}
        loadingMessage={() => "Pesquisando..."}
        menuPosition={"fixed"}
        isLoading={props.isLoading}
        styles={customStyles}
        options={newData}
        {...props}
      />
    </div>
  );
};

export default Select;
