/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Card";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Table from "../../components/Table";
import Button from "../../components/buttons/Button";
import Box from "../../components/containers/Box";
import Column from "../../components/containers/Column";
import Row from "../../components/containers/Row";
import GenericText from "../../components/texts/GenericText";
import changeStateByInputName from "../../helpers/state/changeStateByInputName";
import AssociatesService from "../../services/api/associates/AssociatesService";
import CustomerService from "../../services/api/customer/CustomerService";
import queryKeys from "../../services/utils/queryKeys";
import { EHImage } from "./Registration/styles";
import { AssociatesTableConfig } from "./tables/config";

export default function Associates() {
  let { id, active } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<Record<string, any>>({
    companyId: id && Number(id),
    active:
      active === "ativos" ? true : active === "inativos" ? false : undefined,
  });
  const [cachedData, setCachedData] = useState<Record<string, any>>({});
  const [removeDoubleRender, setRemoveDoubleRender] = useState(true);
  const [focusedFilters, setFocusedFilters] = useState<Record<string, any>>({});

  const [companyName, setCompanyName] = useState("");
  const { data: customers } = useQuery({
    queryKey: [queryKeys.customer.company.list, companyName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 20,
        page: 1,
        name: companyName,
        type: "active",
      }),
  });
  const { isPending, mutate } = useMutation({
    mutationKey: [queryKeys.associate.list],
    mutationFn: AssociatesService.search,
    onSuccess: (data, variables) => {
      const key = JSON.stringify({ page, limit, ...variables });
      setFocusedFilters(variables as never);
      setCachedData((prevData) => ({
        ...prevData,
        [key]: data,
      }));
    },
  });

  const { data: customerDetails } = useQuery({
    queryKey: [queryKeys.customer.details, { id }],
    queryFn: () => CustomerService.details({ id }),
    enabled: !!id,
  });

  function init() {
    const key = JSON.stringify({ page, limit, ...filters });
    if (!isPending && !cachedData[key]?.length) {
      mutate({ page, limit, ...filters });
    }
  }
  useEffect(() => {
    if (removeDoubleRender) {
      setRemoveDoubleRender(false);
      return;
    }
    init();
  }, [removeDoubleRender]);

  const handleSearch = (
    props: {
      cache?: boolean;
      reset?: boolean;
      page?: number;
      limit?: number;
    } = {}
  ) => {
    setPage(props.reset || !props.page ? 1 : props.page);
    if (
      props.cache &&
      cachedData[
        JSON.stringify({
          page: props.page || 1,
          limit: props.limit || limit,
          ...filters,
        })
      ] &&
      !props.reset
    ) {
      return;
    }
    mutate({ page: props.page || 1, limit: props.limit || limit, ...filters });
    setFocusedFilters(filters);
  };

  return (
    <>
      {customerDetails?.id && (
        <Row justifyContent="space-around" margin={"25px"}>
          <Card
            title={String(customerDetails.companiesDatum.activeUsers)}
            subTitle="Associados ativos"
            backgroundColor="white"
            removeButton
          />
          <Card
            title={String(customerDetails.companiesDatum.inactiveUsers)}
            subTitle="Associados inativos"
            backgroundColor="white"
            removeButton
          />

          <Card
            title={String(
              customerDetails.companiesDatum.inactiveUsers +
                customerDetails.companiesDatum.activeUsers
            )}
            subTitle="Associados totais "
            backgroundColor="white"
            removeButton
          />
        </Row>
      )}
      <Box width={"90%"} margin={"10px 0px"} shadow>
        <Column width={"90%"} alignItems="flex-start">
          {!!id && (
            <EHImage style={{ alignSelf: "flex-end" }}>
              <img
                alt="entity-image"
                style={{ width: "75px", height: "75px" }}
                src={`${customerDetails?.companiesImage.image}`}
              />
            </EHImage>
          )}
          <GenericText
            alignSelf="flex-start"
            size="h3"
            margin={"0px 0px 20px 0px"}
          >
            Buscar associado
          </GenericText>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleSearch({ reset: true });
            }}
          >
            <Row wrap="wrap" width={"100%"}>
              {!id && (
                <Row width="auto" margin={"5px 5px"}>
                  <Select
                    data={
                      customers?.data.map((customer) => ({
                        id: customer.id,
                        name: customer.name,
                      })) || []
                    }
                    getOptionLabel={(item: any) => item?.name}
                    getOptionValue={(item: any) => item?.id}
                    onInputChange={(e) => {
                      setCompanyName(e);
                    }}
                    width="220px"
                    placeholder="Empresa"
                    onChange={(e: any) => {
                      setFilters((state) => ({
                        ...state,
                        companyId: e?.id,
                      }));
                    }}
                  />
                </Row>
              )}
              <Input
                name="name"
                onChange={(e) => changeStateByInputName(e, setFilters)}
                width="220px"
                placeholder="Nome"
                margin={"5px 5px"}
              />
              <Input
                name="cpf"
                onChange={(e) => changeStateByInputName(e, setFilters)}
                width="220px"
                placeholder="cpf"
                maskType="cpf"
                margin={"5px 5px"}
              />
              <Input
                name="email"
                onChange={(e) => changeStateByInputName(e, setFilters)}
                width="220px"
                placeholder="email"
                margin={"5px 5px"}
              />
              <Row width="auto" margin={"5px 5px"}>
                <Select
                  value={
                    filters?.active === true
                      ? { name: "Ativo", id: true }
                      : filters?.active === false
                      ? { name: "Inativo", id: false }
                      : ""
                  }
                  data={[
                    { name: "Ativo", id: true },
                    { name: "Inativo", id: false },
                  ]}
                  getOptionLabel={(item: any) => item?.name}
                  getOptionValue={(item: any) => item?.id}
                  width="220px"
                  placeholder="status"
                  onChange={(e: any) => {
                    setFilters((state) => ({
                      ...state,
                      active: e?.id,
                    }));
                  }}
                />
              </Row>
              <Button
                height={"35px"}
                width={"150px"}
                text="Buscar"
                margin={"5px 5px"}
                loading={isPending}
              />
            </Row>
          </form>
        </Column>
      </Box>
      <Row margin={"20px 0px"} width={"90%"}>
        <Table
          data={
            cachedData[JSON.stringify({ page, limit, ...focusedFilters })] || []
          }
          columns={AssociatesTableConfig() as never}
          paginationTotalRows={999}
          progressPending={isPending}
          onRowClicked={(row: any) => {
            navigate(`/associados/detalhes/${row?.id}`);
          }}
          selectableRows
          onChangeRowsPerPage={(newLimit, newPage) => {
            setLimit(newLimit);
            setPage(newPage);
            handleSearch({ cache: true, page: newPage, limit: newLimit });
          }}
          onChangePage={(newPage) => {
            if (newPage === page) {
              return;
            }
            handleSearch({ cache: true, page: newPage });
          }}
        />
      </Row>
    </>
  );
}
