/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import Box from "../../components/containers/Box";
import Column from "../../components/containers/Column";
import Row from "../../components/containers/Row";
import LoaderGlobal from "../../components/Exotics/loaders/LoaderGlobal";
import { Seprator } from "../../components/Exotics/Seprator";
import InputSearch from "../../components/Input/InputSearch";
import Select from "../../components/Select";
import BoxSelect from "../../components/Select/BoxSelect";
import Table from "../../components/Table";
import GenericText from "../../components/texts/GenericText";
import useDebounce from "../../helpers/hooks/useDebounce";
import useLocation from "../../hooks/useLocation";
import { IState } from "../../interfaces/customer/ICustomer";
import CustomerService from "../../services/api/customer/CustomerService";
import ProductsService from "../../services/api/products/ProductsService";
import ICities from "../../services/api/utils/interfaces/ICities";
import queryKeys from "../../services/utils/queryKeys";
import { customersTableConfig } from "./tables/customersHome";

const Customers = () => {
  const navigate = useNavigate();
  const { states, isLoadingStates, cities, getCities } = useLocation();
  const { data: summary, isLoading: isLoadingSummary } = useQuery({
    queryKey: [queryKeys.customer.numbers],
    queryFn: () => CustomerService.getSummary(),
  });

  const [selectState, setSelectState] = useState<IState>({
    id: "" as never,
    name: "Todos",
    regionId: "" as never,
    abbreviation: null,
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchName, setSerachName] = useState("");
  const [productId, setProductId] = useState();
  const [segmentId, setSegmentId] = useState();
  const debounceSearchName = useDebounce(searchName);

  const [selectCities, setSelectCites] = useState<ICities>({
    id: "" as never,
    name: "Todas",
    stateId: "" as never,
  });

  const { data: dataTable } = useQuery({
    queryKey: [
      queryKeys.customer.list,
      page,
      debounceSearchName,
      selectState,
      limit,
      productId,
      segmentId,
    ],
    queryFn: () =>
      CustomerService.getCompany({
        limit,
        page,
        state: selectState.id,
        name: debounceSearchName,
        productId,
        segmentId,
      }),
  });

  const { data: products, isLoading: isLoadingProducts } = useQuery({
    queryKey: [queryKeys.products.list],
    queryFn: () => ProductsService.search(),
  });
  const { data: segments, isLoading: isLoadingSegments } = useQuery({
    queryKey: [queryKeys.customer.segment.list],
    queryFn: () => CustomerService.getSegments(),
  });

  useEffect(() => {
    if (selectState?.name === "Selecione") {
      getCities("0");
    } else if (selectState?.id?.toString() !== "") {
      getCities(selectState?.id?.toString());
    }
  }, [selectState?.id, selectState?.name]);

  return (
    <>
      <Box
        width="99%"
        alignItems="flex-start"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row justifyContent="space-around" margin={"10px 0px"}>
          <Card
            title={summary?.active.toString() || ""}
            subTitle="Clientes ativos"
            backgroundColor="white"
          />
          <Card
            title={summary?.inactive.toString() || ""}
            subTitle="Clientes inativos"
            backgroundColor="white"
          />
          <Card
            title={summary?.courtesy.toString() || ""}
            subTitle="Clientes cortesia"
            backgroundColor="white"
          />
        </Row>
        <Seprator margin="20px 0px" />
        <Column width={"95%"} alignSelf="center">
          <GenericText font="Bold" size="h4" alignSelf="flex-start">
            Buscadores
          </GenericText>
          <Row justifyContent="space-between" margin={"30px 0px"}>
            <BoxSelect
              fatherWidth="44%"
              width="90%"
              labelContainer="Buscar estado"
              data={states || []}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              value={selectState}
              onChange={(e) => setSelectState(e as any)}
              placeholder="Selecione"
              isLoading={isLoadingStates}
            />
            <BoxSelect
              fatherWidth="44%"
              width="90%"
              labelContainer="Buscar cidade"
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              data={(cities[selectState?.id] as any) || []}
              value={selectCities}
              onChange={(e) => setSelectCites(e as any)}
              placeholder="Selecione"
            />
          </Row>
        </Column>
        <Seprator margin="20px 0px" />
        <Row width={"95%"} alignSelf="center">
          <Table
            data={dataTable?.data || []}
            columns={customersTableConfig({
              onClick: (row: any) => navigate(`/clientes/detalhes/${row?.id}`),
            })}
            paginationTotalRows={dataTable?.count}
            progressPending={!dataTable}
            onRowClicked={(row: any) => {
              navigate(`/clientes/detalhes/${row?.id}`);
            }}
            selectableRows
            onChangeRowsPerPage={(limit, page) => {
              setLimit(limit);
              setPage(page);
            }}
            onChangePage={(newPage) => {
              if (newPage === page) {
                return;
              }
              setPage(newPage);
            }}
            headerComponents={[
              <InputSearch
                type="text"
                width="20%"
                value={searchName}
                label="Pesquisar por nome"
                onChange={(e) => setSerachName(e.target.value)}
                placeholder="Digite o nome"
              />,
              <Select
                data={
                  products?.map((product) => ({
                    id: product.id,
                    name: product.appName,
                  })) || []
                }
                width="300px"
                label="Produto "
                value={products?.filter((product) => product.id === productId)}
                getOptionLabel={(option: any) => option.name}
                onChange={(e: any) => setProductId(e.id)}
                placeholder="Selecione um Produto"
                getOptionValue={(option: any) => option.id}
              />,
              <Select
                data={segments || []}
                width="300px"
                label="Segmento"
                placeholder="Selecione um Segmento"
                value={segments?.filter((segment) => segment.id === segmentId)}
                getOptionLabel={(option: any) => option.name}
                onChange={(e: any) => setSegmentId(e.id)}
                getOptionValue={(option: any) => option.id}
              />,
            ]}
          />
        </Row>
      </Box>
      {(isLoadingSummary || isLoadingStates || isLoadingProducts) && (
        <LoaderGlobal />
      )}
    </>
  );
};

export default Customers;
