import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useNavigationContext from "../hooks/useNavigationContext";

interface PublicProps {
  children: React.ReactNode;
  pageId?: number;
}

const Public = ({ children, pageId }: PublicProps) => {
  const { token } = useAuth();
  const { pages } = useNavigationContext();

  const hasAccess = useMemo(() => {
    return pageId && pages.length
      ? pages.some((page) => page.id === pageId)
      : true;
  }, [pageId, pages]);

  if (!token) {
    return <Navigate to="/autenticacao/login" />;
  }

  if (!hasAccess) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};

export default Public;
