/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSCContainer } from "../../../components/containers/ContentSpacingContainer/styles";
import Row from "../../../components/containers/Row";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal";
import RegistrationForm from "../../../components/forms/RegistrationForm";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import changeStateByInputName from "../../../helpers/state/changeStateByInputName";
import useVerifyObjectEqual from "../../../hooks/useVerifyObjectEqual";
import AccessService from "../../../services/api/access/AccessService";
import { IAccess } from "../../../services/api/access/interface/IAccess";
import { IModule } from "../../../services/api/modules/interface/IModule";
import moduleService from "../../../services/api/modules/moduleService";
import queryKeys from "../../../services/utils/queryKeys";

const AccessDetails = () => {
  const defaultPermissions = [
    { id: 1, name: "leitura", defaultName: "read" },
    { id: 2, name: "criar", defaultName: "create" },
    { id: 3, name: "atualizar", defaultName: "update" },
    { id: 4, name: "excluir", defaultName: "delete" },
  ];

  let { id } = useParams();
  const navigate = useNavigate();
  if (!id) {
    navigate("/");
    return <></>;
  }

  const [editAccess, setEditAccess] = useState<Record<string, any>>({});
  const [initialObject, setInitialObject] = useState<Record<string, any>>({});

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: editAccess,
  });

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.access.details, id],
    queryFn: () => AccessService.getOneAccess(id),
  });

  const { data: modules, isLoading: isLoadingModules } = useQuery({
    queryKey: [queryKeys.module.list],
    queryFn: moduleService.getModule,
  });

  const { mutate } = useMutation({
    mutationKey: [queryKeys.access.update],
    mutationFn: ({ body, id }: { body: Omit<IAccess, "id">; id: number }) =>
      AccessService.updateAccess({ body, id }),
    onSuccess: () => {
      toast.success("Cargo atualizado com sucesso!");
      setInitialObject(editAccess);
    },
  });

  const handlePermissionChange = (
    moduleActual: IModule,
    selectedPermissions: any
  ) => {
    // Mapeia os valores selecionados (permissões)
    const selectedPermissionNames = selectedPermissions.map(
      (option: any) => option.defaultName
    );

    // Cria o objeto de permissões no formato esperado
    const permissionsObject = defaultPermissions.reduce(
      (acc: any, permission: any) => {
        acc[permission.defaultName] = selectedPermissionNames.includes(
          permission.defaultName
        );
        return acc;
      },
      {}
    );

    setEditAccess((prevState) => {
      // Filtra as permissões existentes para remover o módulo se não houver permissões selecionadas
      let updatedPermissions = prevState.permissions.map((permission: any) => {
        if (permission.moduleId === moduleActual.id) {
          if (selectedPermissions.length === 0) {
            return null;
          }
          return {
            ...permission,
            permissions: permissionsObject,
          };
        }
        return permission;
      });

      // Remove os módulos com permissões nulas
      updatedPermissions = updatedPermissions.filter(Boolean);

      // Se o módulo não existir, adiciona um novo item no array de permissões
      if (
        !prevState.permissions.some(
          (permission: any) => permission.moduleId === moduleActual.id
        ) &&
        selectedPermissions.length > 0
      ) {
        updatedPermissions.push({
          accessId: prevState.id,
          moduleId: moduleActual.id,
          permissions: permissionsObject,
          module: {
            id: moduleActual.id,
            name: `Módulo ${moduleActual.name}`,
            role: prevState.role,
            isDefault: false,
          },
        });
      }

      return {
        ...prevState,
        permissions: updatedPermissions, // Atualiza o estado com as novas permissões
      };
    });
  };

  const handleUpdate = () => {
    const { id, ...restBody } = editAccess;
    mutate({ body: restBody as any, id });
  };

  useEffect(() => {
    if (data) {
      setEditAccess(data);
      setInitialObject(data);
      return;
    }
  }, [data]);

  return (
    <>
      <RegistrationForm
        title="Editar Cargo"
        buttonProps={{ onClick: handleUpdate, disabled: disabledButton }}
        renderHeaderComponent={() => (
          <Row justifyContent="space-between" width={"55%"}>
            <Input
              onChange={(e) => changeStateByInputName(e, setEditAccess)}
              name="name"
              value={editAccess?.name || ""}
              width={"300px"}
              label="Nome"
              type="text"
              required
              placeholder="Nome"
            />
          </Row>
        )}
        renderInsideComponents={() => (
          <CSCContainer>
            {modules?.data
              .filter((item) => item.isDefault !== true)
              .map((item, index) => (
                <Row
                  style={{ marginBottom: "4px" }}
                  key={`modules-access-details-${index}`}
                >
                  <Select
                    data={defaultPermissions}
                    getOptionLabel={(option: any) => option?.name}
                    getOptionValue={(option: any) => option?.id}
                    onChange={(e: any) => {
                      handlePermissionChange(item, e);
                    }}
                    value={defaultPermissions.filter(
                      (permission) =>
                        editAccess.permissions?.find(
                          (p: any) => p.moduleId === item.id
                        )?.permissions?.[permission.defaultName]
                    )}
                    label={item.name}
                    removeDefaultValue
                    placeholder={`Selecione as permissões do módulo ${item.name}`}
                    isMulti
                  />
                </Row>
              ))}
          </CSCContainer>
        )}
      />
      {(isLoading || isLoadingModules) && <LoaderGlobal />}
    </>
  );
};

export default AccessDetails;
