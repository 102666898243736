/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useEffect, useMemo, useState } from "react";
import useAuth from "../hooks/useAuth";
import { IPage } from "../services/api/profile/interface/IProfile";
import ProfileService from "../services/api/profile/ProfileService";
import queryKeys from "../services/utils/queryKeys";
import { INavigationContext } from "./INavigationContext";
import NavigationContext from "./NavigationContext";

const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const { token } = useAuth();
  const [pages, setPage] = useState<IPage[]>([]);
  const isDev = process.env.REACT_APP_DEV === "true";
  const devUrl = process.env.REACT_APP_URL;
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.profile.details],
    queryFn: ProfileService.getProfile,
    enabled: !!token,
  });
  const initPages = () => {
    if (data) {
      const pages = data.pages;

      if (isDev && devUrl) {
        const updatedPages = pages.map((route) => {
          // Atualizar o slug principal
          const updatedSlug = route.slug.includes(
            "https://starbase.stations.cloud/"
          )
            ? route.slug.replace(
                "https://starbase.stations.cloud/",
                `${devUrl}/`
              )
            : route.slug;

          // Atualizar os slugs dos filhos
          const updatedChildrens = route.childrens.map((child) => ({
            ...child,
            slug: child.slug.includes("https://starbase.stations.cloud/")
              ? child.slug.replace(
                  "https://starbase.stations.cloud/",
                  `${devUrl}/`
                )
              : child.slug,
          }));

          // Retornar a rota atualizada
          return {
            ...route,
            slug: updatedSlug,
            childrens: updatedChildrens,
          };
        });

        setPage(updatedPages);
        return;
      }

      // Caso não seja dev mode, mantém as páginas como estão
      setPage(pages);
    }
  };

  useEffect(() => {
    initPages();
  }, [data, devUrl, isDev]);

  const value: INavigationContext = useMemo(
    () => ({
      pages,
      isLoading,
    }),
    [pages, isLoading]
  );

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
