/* eslint-disable react-hooks/exhaustive-deps */
import LogoIcon from "../../assets/svg/LogoIcon";
import useNavigationContext from "../../hooks/useNavigationContext";
import LoaderGlobal from "../Exotics/loaders/LoaderGlobal";
import SidebarItem from "./SlideBarItem";
import {
  LogoLink,
  NavbarWrapper,
  NavList,
  SidebarCustom,
  Wrap,
} from "./styles";

const Navbar = () => {
  const { pages, isLoading } = useNavigationContext();

  return (
    <>
      <NavbarWrapper>
        <Wrap>
          <LogoLink to="/">
            <LogoIcon width={150} height={50} />
          </LogoLink>
          <NavList>
            <SidebarCustom>
              {pages.map((page, i) => (
                <SidebarItem
                  item={page}
                  key={`items-side-bar-${page.title}-${i}`}
                />
              ))}
            </SidebarCustom>
          </NavList>
        </Wrap>
      </NavbarWrapper>
      {isLoading && <LoaderGlobal />}
    </>
  );
};

export default Navbar;
